import React from 'react';

const Nav = () => {
  return (
    <nav className="bg-[#FEF8F6] absolute top-0 left-0 right-0 flex justify-center p-4 items-center z-20">
      <div className="text-center">
        <img src="/logo.png" alt="FUNERAL VIDEOS NI" className="h-24 mx-auto" /> {/* Adjust height as needed */}
      </div>
    </nav>
    // <nav className="font-sans text-black absolute top-0 left-0 right-0 flex justify-between items-center py-8 px-16 z-20 font-serif uppercase">
    //   <div className="text-2xl cursor-pointer">FUNERAL VIDEOS NI</div>
    //   <div className="space-x-6">
    //     <a href="#home" className="cursor-pointer hover:underline">Home</a>
    //     <a href="#services" className="cursor-pointer hover:underline">Services</a>
    //     <a href="#about" className="cursor-pointer hover:underline">About</a>
    //     <a href="#contact" className="cursor-pointer hover:underline">Contact</a>
    //   </div>
    // </nav>
  );
};

export default Nav;