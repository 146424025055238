import React from 'react';

const Header = () => {
  const handleContactClick = (e) => {
    e.preventDefault();
    const contactSection = document.getElementById('contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className="bg-[#FEF8F6] pt-28">
      <div className="px-16 py-12">
        <div className="flex justify-between items-center">
          <div className="w-[52%]">
            <h1 className="text-6xl font-serif text-black leading-tight mb-8">
              Cherish Their Memory,<br />
              Celebrate Their Life
            </h1>
            <p className="text-gray-800 text-xl leading-relaxed mb-8">
              Preserve the precious moments of your loved one's farewell with our compassionate and discreet funeral video services.
            </p>
            <a 
              href="#contact" 
              onClick={handleContactClick}
              className="font-serif text-[#4a4a4a] bg-transparent py-3 px-8 rounded-md w-max border border-[#4a4a4a] hover:bg-[#4a4a4a] hover:text-white transition-all duration-300 ease-in-out shadow-md text-xl inline-block cursor-pointer"
            >
              Contact Us
            </a>
          </div>
          <div className="w-[45%]">
            <div className="rounded-lg overflow-hidden">
              <img 
                src="/hero.jpg" 
                alt="Memorial flowers" 
                className="w-full h-[600px] object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;