import React, { useState } from 'react';

const ServiceAccordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-t border-gray-700">
      <button
        className="flex justify-between w-full py-6 text-left text-black"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-xl">{title}</span>
        <span className="text-3xl">{isOpen ? '-' : '+'}</span>
      </button>
      <div 
        className={`overflow-hidden transition-all duration-500 ease-in-out ${
          isOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        <p className="pb-6 text-black text-lg">{content}</p>
      </div>
    </div>
  );
};

const AboutAndServices = () => {
  const services = [
    {
      title: "Professional videography",
      content: "Our professional videography service captures the essence of the funeral ceremony with utmost respect and discretion. Using high-quality equipment and experienced videographers, we ensure that every important moment is recorded sensitively, providing a lasting memorial for family and friends."
    },
    {
      title: "Pre and post service coverage",
      content: "Our comprehensive coverage extends beyond the main ceremony. We offer pre-service filming to capture the arrival of guests and any preparatory moments, as well as post-service coverage of the gathering."
    }
  ];

  return (
    <div className="bg-[#FEF8F6] py-12">
      <div className="px-16">
        <h2 className="text-6xl font-serif text-black leading-tight mb-8">About Us & Services</h2>
        <div className="flex space-x-16">
          <div className="w-1/2">
            <p className="text-gray-800 text-xl leading-relaxed max-w-2xl pr-12">
              We professionally capture funeral services with care, respect, and sensitivity. 
              <br /><br />
              We offer recording of the full ceremony, gatherings & gravesides.
              <br /><br />
              We deliver your memorial video on the same day, ready-to-view and share with your loved ones.
            </p>
          </div>
          <div className="w-1/2">
            {services.map((service, index) => (
              <ServiceAccordion 
                key={index} 
                title={service.title} 
                content={service.content} 
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutAndServices;