import React, { useState, useEffect, useRef } from 'react';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    serviceDate: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const messageRef = useRef(null);
  const form = useRef();

  useEffect(() => {
    const textarea = messageRef.current;
    const adjustHeight = () => {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    };
    textarea.addEventListener('input', adjustHeight);
    return () => textarea.removeEventListener('input', adjustHeight);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      const result = await emailjs.sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      );

      if (result.text === 'OK') {
        setSubmitStatus('success');
        setFormData({
          name: '',
          email: '',
          phone: '',
          serviceDate: '',
          message: ''
        });
      } else {
        setSubmitStatus('error');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderFormContent = () => {
    if (submitStatus === 'success') {
      return (
        <div className="text-center">
          <h3 className="text-2xl font-bold mb-4 text-gray-800">Thank You!</h3>
          <p className="mb-4 text-gray-700">We have received your message and will get back to you soon.</p>
          <button 
            onClick={() => setSubmitStatus(null)} 
            className="bg-gray-800 text-white hover:bg-white hover:text-gray-800 hover:border hover:border-gray-800 font-bold py-2 px-6 rounded-full transition duration-300"
          >
            Send Another Message
          </button>
        </div>
      );
    }

    return (
      <form ref={form} onSubmit={handleSubmit}>
        <div className="mb-6">
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            placeholder="Name"
            className="w-full px-0 py-2 text-gray-800 bg-transparent border-b border-gray-400 focus:border-gray-800 focus:outline-none transition duration-300 placeholder-gray-500"
          />
        </div>
        <div className="mb-6">
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            placeholder="Email"
            className="w-full px-0 py-2 text-gray-800 bg-transparent border-b border-gray-400 focus:border-gray-800 focus:outline-none transition duration-300 placeholder-gray-500"
          />
        </div>
        <div className="mb-6">
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Phone"
            className="w-full px-0 py-2 text-gray-800 bg-transparent border-b border-gray-400 focus:border-gray-800 focus:outline-none transition duration-300 placeholder-gray-500"
          />
        </div>
        <div className="mb-6 relative">
          <input
            type="date"
            id="serviceDate"
            name="serviceDate"
            value={formData.serviceDate}
            onChange={handleChange}
            placeholder="Date of Service"
            className="w-full px-0 py-2 text-gray-800 bg-transparent border-b border-gray-400 focus:border-gray-800 focus:outline-none transition duration-300 placeholder-gray-500 appearance-none"
          />
          <style jsx>{`
            input[type="date"]::-webkit-calendar-picker-indicator {
              filter: invert(0.5);
              opacity: 0.7;
              cursor: pointer;
            }
          `}</style>
        </div>
        <div className="mb-6">
          <textarea
            ref={messageRef}
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Message"
            className="w-full px-0 py-2 text-gray-800 bg-transparent border-b border-gray-400 focus:border-gray-800 focus:outline-none transition duration-300 placeholder-gray-500 resize-none overflow-hidden min-h-[2.5rem]"
            style={{ height: '2.5rem' }}
          ></textarea>
        </div>
        <div className="text-center md:text-left">
          <button 
            type="submit" 
            className="font-serif text-[#4a4a4a] bg-transparent py-3 px-8 rounded-md w-max border border-[#4a4a4a] hover:bg-[#4a4a4a] hover:text-white transition-all duration-300 ease-in-out shadow-md"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Sending...' : 'Send Message'}
          </button>
        </div>
        {submitStatus === 'error' && (
          <p className="mt-4 text-center text-sm text-red-600">
            An error occurred. Please try again.
          </p>
        )}
      </form>
    );
  };

  return (
    <div id="contact" className="bg-[#FEF8F6] py-12">
      <div className="px-16">
        <h2 className="text-6xl font-serif text-black leading-tight mb-8">Contact Us</h2>
        <div className="flex space-x-16">
          <div className="w-1/2">
            {renderFormContent()}
          </div>
          <div className="w-1/2 flex flex-col justify-start">
            <div>
              <h3 className="text-gray-600 uppercase text-xl mb-2">Email</h3>
              <a href="mailto:info@funeralvideosni.com" className="text-black font-serif text-lg hover:underline">funeralvideosni@gmail.com</a>
            </div>
            <div className="mt-8">
              <h3 className="text-gray-600 uppercase text-xl mb-2">Phone</h3>
              <p className="text-black font-serif text-lg">07754210973</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;