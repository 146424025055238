import React from 'react';
import './App.css'
import Nav from './components/nav';
import Header from './components/header';
import AboutAndServices from './components/aboutandservices';
import ContactForm from './components/contact';

function App() {
  return (
    <div className="App">
      <Nav />
      <Header />
      <AboutAndServices />
      <ContactForm />
      {/* Other components */}
    </div>
  );
}

export default App;